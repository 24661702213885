.t {
  transform: none;
}
[platform] .holder {
  position: relative;
  height: 100vh;
  box-sizing: border-box;
  overflow: hidden;
}
[platform] .hero {
  position: relative;
  height: 400vh;
}
[platform] .hero .holder {
  padding-top: 3.75rem;
}
[platform] .hero h2,
[platform] .hero p {
  position: absolute;
  left: 50%;
  top: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
}
[platform] .hero h2 span,
[platform] .hero p span {
  display: block;
}
[platform] .hero .scene-1 .bg {
  position: absolute;
  left: 0rem;
  top: 0rem;
  right: auto;
  bottom: auto;
  background-color: #000;
  width: 100%;
  height: 100%;
}
[platform] .hero .scene-1 .bg video {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
[platform] .hero .scene-1 h2 img {
  display: block;
  width: 19.625rem;
}
[platform] .hero .scene-1 h2 ~ p {
  width: 100%;
  text-align: center;
  font-size: 3.5rem;
  line-height: 4rem;
  font-weight: 700;
  color: #1e1e1e;
}
[platform] .hero .scene-1 .cover {
  position: absolute;
  left: 0rem;
  top: 0rem;
  right: auto;
  bottom: auto;
  width: 100%;
  height: 100%;
  background-color: #000;
}
[platform] .hero .scene-2 {
  position: absolute;
  left: 0rem;
  top: 0rem;
  right: auto;
  bottom: auto;
  width: 100%;
  height: 100%;
  background-color: #000;
}
[platform] .hero .scene-2 p {
  font-size: 1.25rem;
  line-height: 2rem;
  font-weight: 700;
  color: #fff;
  width: 100%;
  text-align: center;
}
[platform] .hero .scene-2 p + p {
  font-size: 3.5rem;
  line-height: 4rem;
  
}
[platform] .hero .award {
  display: block;
  position: absolute;
  right: 1.5rem;
  bottom: 1.5rem;
  left: auto;
  top: auto;
  width: 6.5625rem;
}
[platform] .hero .award-2 {
  right: 9.1875rem;
  bottom: 1.5rem;
  left: auto;
  top: auto;
  width: 5rem;
}
[platform] .hero + .hero {
  height: 400vh;
}
[platform] .hero + .hero .holder {
  overflow: visible;
}
[platform] .seq {
  color: #1e1e1e;
  padding-top: 5rem;
  padding-right: 0rem;
  padding-bottom: 5rem;
  padding-left: 0rem;
}
[platform] .seq .holder {
  height: auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
[platform] .seq p {
  font-size: 1.25rem;
  line-height: 0.0625rem;
  line-height: 1;
}
[platform] .seq h3 {
  font-size: 2.25rem;
  line-height: 0.0625rem;
  line-height: 1;
  margin-top: 0.75rem;
}
[platform] .seq a {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-top: 0.75rem;
  padding-right: 3.75rem;
  padding-bottom: 0.75rem;
  padding-left: 3.75rem;
  width: 11.5rem;
  box-sizing: border-box;
  font-size: 1rem;
  line-height: 0.0625rem;
  line-height: 1;
  border-radius: 0.1875rem;
  border: 1px solid #1e1e1e;
  background-color: #fff;
  transition: background-color 0.4s, color 0.4s;
}
.no-touch [platform] .seq a:hover:not(.hover-disable) {
  background-color: #1e1e1e;
  color: #fff;
}
[platform] .seq video {
  display: block;
  position: relative;
  width: 12.125rem;
  margin-top: 3.5rem;
  margin-right: auto;
  margin-bottom: 3.5rem;
  margin-left: auto;
}
[platform] .seq.cartridge video {
  width: 11.125rem;
}
[platform] .seq.os video {
  width: 18.5rem;
}
[platform] .map {
  height: 300vh;
}
[platform] .map .img {
  position: relative;
}
[platform] .map img {
  display: block;
  width: auto;
  height: 100vh;
  object-fit: cover;
  object-position: bottom;
}
[platform] .map img + img {
  position: absolute;
  left: 0rem;
  top: 0rem;
  right: auto;
  bottom: auto;
}
[platform] .reason {
  height: 200vh;
  background-color: #EEEEEE;
}
[platform] .reason .bg {
  position: absolute;
  left: 0rem;
  top: 0rem;
  right: auto;
  bottom: auto;
  width: 100%;
  height: 100%;
}
[platform] .reason .bg video,
[platform] .reason .bg img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
[platform] .reason p {
  position: absolute;
  left: 50%;
  top: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  font-size: 3.5rem;
  line-height: 4rem;
  font-weight: 700;
  color: #1e1e1e;
}
[platform] .feature {
  background-color: #000;
}
[platform] .feature .holder {
  height: auto;
}
[platform] .feature li {
  padding-top: 5rem;
  padding-right: 0rem;
  padding-bottom: 5rem;
  padding-left: 0rem;
  color: #fff;
}
[platform] .feature li .txt > p:first-of-type {
  font-size: 1.25rem;
  line-height: 0.0625rem;
  line-height: 1;
  font-weight: 700;
}
[platform] .feature li .txt > p + p {
  margin-top: 1rem;
  font-size: 2.5rem;
  line-height: 3rem;
  font-weight: 700;
}
[platform] .feature li .txt > p + p + p {
  margin-top: 2.5rem;
  font-size: 1.25rem;
  line-height: 2rem;
  font-weight: 700;
  color: #C9C9C9;
}
[platform] .feature li .txt .bottom {
  margin-top: 2.5rem;
}
[platform] .feature li .txt .bottom p {
  font-weight: 500;
}
[platform] .feature li .txt .bottom p + p {
  margin-top: 1rem;
  font-size: 4rem;
  line-height: 0.0625rem;
  line-height: 1;
}
[platform] .feature li .txt .bottom p + p img {
  display: block;
  height: 4.5rem;
}
[platform] .feature li video {
  display: block;
  margin-top: 3rem;
  width: 100%;
  object-fit: contain;
}
[platform] .eco .holder {
  padding-top: 5rem;
  padding-right: 0rem;
  padding-bottom: 8rem;
  padding-left: 0rem;
  height: auto;
}
[platform] .eco h3 {
  font-size: 2rem;
  line-height: 0.0625rem;
  line-height: 1;
  text-align: center;
}
[platform] .eco .video {
  margin-top: 2.5rem;
}
[platform] .eco .video video {
  display: block;
  width: 22.1875rem;
  margin-left: auto;
  margin-right: auto;
}
[platform] .eco .smart {
  margin-top: 0.625rem;
}
[platform] .eco .smart > p {
  font-size: 0.75rem;
  line-height: 0.0625rem;
  line-height: 1;
  font-weight: 700;
  text-align: center;
}
[platform] .eco .smart ul {
  margin-top: 1rem;
  width: 10.9375rem;
  margin-left: auto;
  margin-right: auto;
}
[platform] .eco .smart li {
  display: flex;
  justify-content: space-between;
  font-size: 0.625rem;
  line-height: 0.0625rem;
  line-height: 1;
}
[platform] .eco .smart li p:first-of-type {
  position: relative;
  padding-left: 1.125rem;
  font-weight: 700;
}
[platform] .eco .smart li p:first-of-type:before {
  content: '';
  display: block;
  position: absolute;
  left: 0rem;
  top: 0rem;
  right: auto;
  bottom: auto;
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 0.3125rem;
  background-color: #DFF4E3;
}
[platform] .eco .smart li p + p {
  text-align: right;
  color: #666;
}
[platform] .eco .smart li + li {
  margin-top: 0.625rem;
}
[platform] .eco .smart li:nth-child(2) p:first-of-type:before {
  background-color: #BEE9C8;
}
[platform] .eco .smart li:nth-child(3) p:first-of-type:before {
  background-color: #98E09B;
}
[platform] .eco .smart li:nth-child(4) p:first-of-type:before {
  background-color: #7DD291;
}
[platform] .eco .smart li:nth-child(5) p:first-of-type:before {
  background-color: #5DC775;
}
[platform] .eco .smart li:nth-child(6) p:first-of-type:before {
  background-color: #4E85F6;
}
[platform] .eco .desc {
  margin-top: 5rem;
  margin-right: 2.25rem;
  margin-bottom: 0rem;
  margin-left: 2.25rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 700;
}
@media (min-width: 768px) {
  [platform] .hero .scene-1 h2 img {
    width: 34.875rem;
  }
  [platform] .hero .scene-1 h2 ~ p {
    font-size: 5rem;
    line-height: 6rem;
    
  }
  [platform] .hero .scene-2 p {
    font-size: 2rem;
    line-height: 2.5rem;
    
  }
  [platform] .hero .scene-2 p + p {
    font-size: 5rem;
    line-height: 6rem;
    
  }
  [platform] .seq video {
    width: 17.5rem;
    margin-top: 4rem;
    margin-right: auto;
    margin-bottom: 4rem;
    margin-left: auto;
  }
  [platform] .seq.cartridge video {
    width: 16.125rem;
  }
  [platform] .seq.os video {
    width: 24rem;
  }
  [platform] .reason {
    background-color: #EBEAEB;
  }
  [platform] .reason p {
    font-size: 5rem;
    line-height: 6rem;
    
  }
  [platform] .feature {
    height: 450vh;
  }
  [platform] .feature ul {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0rem;
  }
  [platform] .feature li {
    position: relative;
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
  }
  [platform] .feature li .txt p {
    width: 18.9375rem;
  }
  [platform] .feature li video {
    position: absolute;
    right: 0rem;
    top: 50%;
    left: auto;
    bottom: auto;
    transform: translateY(-50%);
    width: 18rem;
    height: 18rem;
    object-fit: cover;
    margin: 0;
  }
  [platform] .feature li + li {
    margin-left: 6.25rem;
  }
  [platform] .eco .video video {
    width: 32.5rem;
  }
  [platform] .eco .desc {
    width: 18.9375rem;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 1024px) {
  [platform] h3 {
    font-size: 2.5rem;
    line-height: 0.0625rem;
    line-height: 1;
    text-align: center;
  }
  [platform] .hero .scene-1 h2 img {
    width: 46.5rem;
  }
  [platform] .hero .scene-1 h2 ~ p {
    font-size: 6rem;
    line-height: 7.25rem;
    
  }
  [platform] .hero .scene-2 p + p {
    font-size: 6rem;
    line-height: 7.25rem;
    
  }
  [platform] .seq {
    height: 58rem;
    padding: 0;
  }
  [platform] .seq .holder {
    flex-direction: row;
    height: 100%;
  }
  [platform] .seq .holder > div:first-child {
    position: relative;
    left: 0rem;
    top: 0rem;
    right: auto;
    bottom: auto;
    width: auto;
  }
  [platform] .seq .holder > div {
    flex: 1;
  }
  [platform] .seq .holder p {
    font-size: 1.5rem;
    line-height: 0.0625rem;
    line-height: 1;
  }
  [platform] .seq .holder h3 {
    font-size: 3rem;
    line-height: 0.0625rem;
    line-height: 1;
    margin-top: 1rem;
  }
  [platform] .seq .holder a {
    margin-top: 3rem;
  }
  [platform] .seq video {
    width: auto;
    height: 41rem;
    margin-top: 0rem;
    margin-right: auto;
    margin-bottom: 0rem;
    margin-left: auto;
  }
  [platform] .seq.cartridge video {
    width: auto;
    height: 41rem;
  }
  [platform] .seq.os video {
    width: 24rem;
  }
  [platform] .seq.cartridge .holder {
    flex-direction: row-reverse;
  }
  [platform] .map .img {
    height: 100%;
  }
  [platform] .map video {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: bottom;
    max-width: 100rem;
    margin-left: auto;
    margin-right: auto;
  }
  [platform] .reason p {
    font-size: 6rem;
    line-height: 0.0625rem;
    line-height: 1;
  }
  [platform] .feature li .txt p {
    width: 25rem;
  }
  [platform] .feature li .txt > p:first-of-type {
    font-size: 1.5rem;
    line-height: 0.0625rem;
    line-height: 1;
  }
  [platform] .feature li .txt > p + p {
    margin-top: 1.5rem;
    font-size: 3.5rem;
    line-height: 4.5rem;
    
  }
  [platform] .feature li .txt > p + p + p {
    margin-top: 3.5rem;
    font-size: 1.5rem;
    line-height: 2.5rem;
    
  }
  [platform] .feature li .txt .bottom {
    margin-top: 3.5rem;
  }
  [platform] .feature li .txt .bottom p {
    font-size: 1.25rem;
    line-height: 0.0625rem;
    line-height: 1;
  }
  [platform] .feature li .txt .bottom p + p {
    margin-top: 1.25rem;
    font-size: 5rem;
    line-height: 0.0625rem;
    line-height: 1;
  }
  [platform] .feature li .txt .bottom p + p img {
    height: 5.0625rem;
  }
  [platform] .feature li video {
    width: 25rem;
    height: 25rem;
  }
  [platform] .eco .holder {
    padding-bottom: 9rem;
  }
  [platform] .eco h3 {
    font-size: 2.5rem;
    line-height: 0.0625rem;
    line-height: 1;
  }
  [platform] .eco .video video {
    width: auto;
    height: 21.75rem;
    max-width: 100%;
  }
  [platform] .eco .smart {
    display: none;
  }
  [platform] .eco .desc {
    margin-top: 2.5rem;
    margin-right: auto;
    margin-bottom: 0rem;
    margin-left: auto;
    font-size: 1.5rem;
    line-height: 2.5rem;
    width: auto;
    text-align: center;
  }
}
@media (min-width: 1280px) {
  [platform] .hero .scene-1 h2 img {
    width: 50.375rem;
  }
  [platform] .hero .scene-1 h2 ~ p {
    font-size: 7.5rem;
    line-height: 9rem;
    
  }
  [platform] .hero .scene-2 p + p {
    font-size: 7.5rem;
    line-height: 0.0625rem;
    line-height: 1;
  }
  [platform] .hero .award {
    right: 2rem;
    bottom: 2rem;
    left: auto;
    top: auto;
  }
  [platform] .hero .award-2 {
    right: 9.1875rem;
    bottom: 2rem;
    left: auto;
    top: auto;
  }
  [platform] .seq {
    height: 62.5rem;
  }
  [platform] .seq video {
    width: auto;
    height: 45rem;
  }
  [platform] .seq.cartridge video {
    width: auto;
    height: 45rem;
  }
  [platform] .seq.os video {
    width: 27.75rem;
  }
  [platform] .reason p {
    font-size: 7.5rem;
    line-height: 0.0625rem;
    line-height: 1;
  }
  [platform] .feature li video {
    width: 35rem;
    height: 35rem;
  }
  [platform] .structure img {
    width: 63.625rem;
  }
  [platform] .custom .viewer {
    height: 48rem;
  }
  [platform] .custom .viewer .control {
    width: 35rem;
  }
  [platform] .custom .viewer .control .slider [slider] {
    position: relative;
  }
  [platform] .custom .viewer .control .slider .swiper-navigation .swiper-button-prev {
    left: 7.5rem;
  }
  [platform] .custom .viewer .control .slider .swiper-navigation .swiper-button-next {
    right: 7.5rem;
  }
  [platform] .custom .viewer .control .slider img {
    width: 5rem;
    height: 15rem;
    padding-top: 4rem;
    padding-right: 0rem;
    padding-bottom: 1.5rem;
    padding-left: 0rem;
  }
  [platform] .custom .viewer .result {
    width: 32.5625rem;
  }
  [platform] .spec ul {
    padding-top: 0rem;
    padding-right: 13rem;
    padding-bottom: 0rem;
    padding-left: 13rem;
  }
  [platform] .spec li:first-child dt:first-child {
    margin-left: -13rem;
  }
  [platform] .spec li:last-child dt:first-child {
    margin-right: -13rem;
  }
  [platform] .eco {
    height: 600vh;
  }
  [platform] .eco .holder {
    height: 100vh;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  [platform] .eco .img {
    position: relative;
    width: 80rem;
    height: 27.125rem;
    margin-left: auto;
    margin-right: auto;
  }
  [platform] .eco .img img {
    display: block;
    margin-top: 0.875rem;
    position: absolute;
    left: 50%;
    top: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%);
    height: 100%;
    opacity: 0;
    object-fit: contain;
  }
  [platform] .eco[data-sq="-"] .sq-1,
  [platform] .eco[data-sq="0"] .sq-1 {
    opacity: 1;
  }
  [platform] .eco[data-sq="+"] .sq-221 {
    opacity: 1;
  }
  [platform] .eco[data-sq="221"] .sq-220 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="221"] .sq-222 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="221"] .sq-221 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="220"] .sq-219 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="220"] .sq-221 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="220"] .sq-220 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="219"] .sq-218 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="219"] .sq-220 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="219"] .sq-219 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="218"] .sq-217 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="218"] .sq-219 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="218"] .sq-218 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="217"] .sq-216 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="217"] .sq-218 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="217"] .sq-217 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="216"] .sq-215 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="216"] .sq-217 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="216"] .sq-216 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="215"] .sq-214 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="215"] .sq-216 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="215"] .sq-215 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="214"] .sq-213 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="214"] .sq-215 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="214"] .sq-214 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="213"] .sq-212 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="213"] .sq-214 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="213"] .sq-213 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="212"] .sq-211 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="212"] .sq-213 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="212"] .sq-212 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="211"] .sq-210 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="211"] .sq-212 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="211"] .sq-211 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="210"] .sq-209 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="210"] .sq-211 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="210"] .sq-210 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="209"] .sq-208 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="209"] .sq-210 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="209"] .sq-209 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="208"] .sq-207 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="208"] .sq-209 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="208"] .sq-208 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="207"] .sq-206 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="207"] .sq-208 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="207"] .sq-207 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="206"] .sq-205 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="206"] .sq-207 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="206"] .sq-206 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="205"] .sq-204 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="205"] .sq-206 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="205"] .sq-205 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="204"] .sq-203 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="204"] .sq-205 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="204"] .sq-204 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="203"] .sq-202 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="203"] .sq-204 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="203"] .sq-203 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="202"] .sq-201 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="202"] .sq-203 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="202"] .sq-202 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="201"] .sq-200 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="201"] .sq-202 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="201"] .sq-201 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="200"] .sq-199 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="200"] .sq-201 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="200"] .sq-200 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="199"] .sq-198 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="199"] .sq-200 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="199"] .sq-199 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="198"] .sq-197 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="198"] .sq-199 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="198"] .sq-198 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="197"] .sq-196 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="197"] .sq-198 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="197"] .sq-197 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="196"] .sq-195 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="196"] .sq-197 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="196"] .sq-196 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="195"] .sq-194 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="195"] .sq-196 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="195"] .sq-195 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="194"] .sq-193 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="194"] .sq-195 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="194"] .sq-194 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="193"] .sq-192 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="193"] .sq-194 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="193"] .sq-193 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="192"] .sq-191 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="192"] .sq-193 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="192"] .sq-192 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="191"] .sq-190 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="191"] .sq-192 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="191"] .sq-191 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="190"] .sq-189 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="190"] .sq-191 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="190"] .sq-190 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="189"] .sq-188 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="189"] .sq-190 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="189"] .sq-189 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="188"] .sq-187 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="188"] .sq-189 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="188"] .sq-188 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="187"] .sq-186 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="187"] .sq-188 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="187"] .sq-187 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="186"] .sq-185 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="186"] .sq-187 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="186"] .sq-186 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="185"] .sq-184 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="185"] .sq-186 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="185"] .sq-185 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="184"] .sq-183 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="184"] .sq-185 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="184"] .sq-184 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="183"] .sq-182 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="183"] .sq-184 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="183"] .sq-183 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="182"] .sq-181 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="182"] .sq-183 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="182"] .sq-182 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="181"] .sq-180 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="181"] .sq-182 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="181"] .sq-181 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="180"] .sq-179 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="180"] .sq-181 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="180"] .sq-180 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="179"] .sq-178 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="179"] .sq-180 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="179"] .sq-179 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="178"] .sq-177 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="178"] .sq-179 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="178"] .sq-178 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="177"] .sq-176 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="177"] .sq-178 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="177"] .sq-177 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="176"] .sq-175 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="176"] .sq-177 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="176"] .sq-176 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="175"] .sq-174 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="175"] .sq-176 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="175"] .sq-175 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="174"] .sq-173 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="174"] .sq-175 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="174"] .sq-174 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="173"] .sq-172 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="173"] .sq-174 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="173"] .sq-173 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="172"] .sq-171 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="172"] .sq-173 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="172"] .sq-172 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="171"] .sq-170 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="171"] .sq-172 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="171"] .sq-171 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="170"] .sq-169 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="170"] .sq-171 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="170"] .sq-170 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="169"] .sq-168 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="169"] .sq-170 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="169"] .sq-169 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="168"] .sq-167 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="168"] .sq-169 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="168"] .sq-168 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="167"] .sq-166 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="167"] .sq-168 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="167"] .sq-167 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="166"] .sq-165 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="166"] .sq-167 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="166"] .sq-166 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="165"] .sq-164 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="165"] .sq-166 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="165"] .sq-165 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="164"] .sq-163 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="164"] .sq-165 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="164"] .sq-164 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="163"] .sq-162 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="163"] .sq-164 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="163"] .sq-163 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="162"] .sq-161 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="162"] .sq-163 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="162"] .sq-162 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="161"] .sq-160 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="161"] .sq-162 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="161"] .sq-161 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="160"] .sq-159 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="160"] .sq-161 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="160"] .sq-160 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="159"] .sq-158 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="159"] .sq-160 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="159"] .sq-159 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="158"] .sq-157 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="158"] .sq-159 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="158"] .sq-158 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="157"] .sq-156 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="157"] .sq-158 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="157"] .sq-157 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="156"] .sq-155 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="156"] .sq-157 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="156"] .sq-156 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="155"] .sq-154 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="155"] .sq-156 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="155"] .sq-155 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="154"] .sq-153 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="154"] .sq-155 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="154"] .sq-154 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="153"] .sq-152 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="153"] .sq-154 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="153"] .sq-153 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="152"] .sq-151 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="152"] .sq-153 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="152"] .sq-152 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="151"] .sq-150 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="151"] .sq-152 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="151"] .sq-151 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="150"] .sq-149 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="150"] .sq-151 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="150"] .sq-150 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="149"] .sq-148 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="149"] .sq-150 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="149"] .sq-149 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="148"] .sq-147 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="148"] .sq-149 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="148"] .sq-148 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="147"] .sq-146 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="147"] .sq-148 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="147"] .sq-147 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="146"] .sq-145 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="146"] .sq-147 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="146"] .sq-146 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="145"] .sq-144 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="145"] .sq-146 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="145"] .sq-145 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="144"] .sq-143 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="144"] .sq-145 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="144"] .sq-144 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="143"] .sq-142 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="143"] .sq-144 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="143"] .sq-143 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="142"] .sq-141 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="142"] .sq-143 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="142"] .sq-142 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="141"] .sq-140 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="141"] .sq-142 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="141"] .sq-141 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="140"] .sq-139 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="140"] .sq-141 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="140"] .sq-140 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="139"] .sq-138 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="139"] .sq-140 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="139"] .sq-139 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="138"] .sq-137 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="138"] .sq-139 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="138"] .sq-138 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="137"] .sq-136 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="137"] .sq-138 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="137"] .sq-137 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="136"] .sq-135 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="136"] .sq-137 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="136"] .sq-136 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="135"] .sq-134 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="135"] .sq-136 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="135"] .sq-135 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="134"] .sq-133 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="134"] .sq-135 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="134"] .sq-134 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="133"] .sq-132 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="133"] .sq-134 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="133"] .sq-133 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="132"] .sq-131 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="132"] .sq-133 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="132"] .sq-132 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="131"] .sq-130 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="131"] .sq-132 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="131"] .sq-131 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="130"] .sq-129 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="130"] .sq-131 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="130"] .sq-130 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="129"] .sq-128 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="129"] .sq-130 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="129"] .sq-129 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="128"] .sq-127 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="128"] .sq-129 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="128"] .sq-128 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="127"] .sq-126 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="127"] .sq-128 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="127"] .sq-127 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="126"] .sq-125 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="126"] .sq-127 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="126"] .sq-126 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="125"] .sq-124 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="125"] .sq-126 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="125"] .sq-125 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="124"] .sq-123 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="124"] .sq-125 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="124"] .sq-124 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="123"] .sq-122 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="123"] .sq-124 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="123"] .sq-123 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="122"] .sq-121 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="122"] .sq-123 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="122"] .sq-122 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="121"] .sq-120 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="121"] .sq-122 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="121"] .sq-121 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="120"] .sq-119 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="120"] .sq-121 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="120"] .sq-120 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="119"] .sq-118 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="119"] .sq-120 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="119"] .sq-119 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="118"] .sq-117 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="118"] .sq-119 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="118"] .sq-118 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="117"] .sq-116 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="117"] .sq-118 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="117"] .sq-117 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="116"] .sq-115 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="116"] .sq-117 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="116"] .sq-116 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="115"] .sq-114 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="115"] .sq-116 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="115"] .sq-115 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="114"] .sq-113 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="114"] .sq-115 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="114"] .sq-114 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="113"] .sq-112 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="113"] .sq-114 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="113"] .sq-113 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="112"] .sq-111 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="112"] .sq-113 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="112"] .sq-112 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="111"] .sq-110 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="111"] .sq-112 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="111"] .sq-111 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="110"] .sq-109 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="110"] .sq-111 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="110"] .sq-110 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="109"] .sq-108 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="109"] .sq-110 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="109"] .sq-109 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="108"] .sq-107 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="108"] .sq-109 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="108"] .sq-108 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="107"] .sq-106 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="107"] .sq-108 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="107"] .sq-107 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="106"] .sq-105 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="106"] .sq-107 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="106"] .sq-106 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="105"] .sq-104 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="105"] .sq-106 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="105"] .sq-105 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="104"] .sq-103 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="104"] .sq-105 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="104"] .sq-104 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="103"] .sq-102 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="103"] .sq-104 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="103"] .sq-103 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="102"] .sq-101 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="102"] .sq-103 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="102"] .sq-102 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="101"] .sq-100 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="101"] .sq-102 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="101"] .sq-101 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="100"] .sq-99 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="100"] .sq-101 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="100"] .sq-100 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="99"] .sq-98 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="99"] .sq-100 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="99"] .sq-99 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="98"] .sq-97 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="98"] .sq-99 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="98"] .sq-98 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="97"] .sq-96 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="97"] .sq-98 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="97"] .sq-97 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="96"] .sq-95 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="96"] .sq-97 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="96"] .sq-96 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="95"] .sq-94 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="95"] .sq-96 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="95"] .sq-95 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="94"] .sq-93 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="94"] .sq-95 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="94"] .sq-94 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="93"] .sq-92 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="93"] .sq-94 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="93"] .sq-93 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="92"] .sq-91 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="92"] .sq-93 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="92"] .sq-92 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="91"] .sq-90 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="91"] .sq-92 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="91"] .sq-91 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="90"] .sq-89 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="90"] .sq-91 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="90"] .sq-90 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="89"] .sq-88 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="89"] .sq-90 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="89"] .sq-89 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="88"] .sq-87 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="88"] .sq-89 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="88"] .sq-88 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="87"] .sq-86 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="87"] .sq-88 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="87"] .sq-87 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="86"] .sq-85 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="86"] .sq-87 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="86"] .sq-86 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="85"] .sq-84 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="85"] .sq-86 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="85"] .sq-85 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="84"] .sq-83 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="84"] .sq-85 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="84"] .sq-84 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="83"] .sq-82 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="83"] .sq-84 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="83"] .sq-83 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="82"] .sq-81 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="82"] .sq-83 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="82"] .sq-82 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="81"] .sq-80 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="81"] .sq-82 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="81"] .sq-81 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="80"] .sq-79 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="80"] .sq-81 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="80"] .sq-80 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="79"] .sq-78 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="79"] .sq-80 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="79"] .sq-79 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="78"] .sq-77 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="78"] .sq-79 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="78"] .sq-78 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="77"] .sq-76 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="77"] .sq-78 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="77"] .sq-77 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="76"] .sq-75 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="76"] .sq-77 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="76"] .sq-76 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="75"] .sq-74 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="75"] .sq-76 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="75"] .sq-75 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="74"] .sq-73 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="74"] .sq-75 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="74"] .sq-74 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="73"] .sq-72 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="73"] .sq-74 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="73"] .sq-73 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="72"] .sq-71 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="72"] .sq-73 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="72"] .sq-72 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="71"] .sq-70 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="71"] .sq-72 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="71"] .sq-71 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="70"] .sq-69 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="70"] .sq-71 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="70"] .sq-70 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="69"] .sq-68 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="69"] .sq-70 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="69"] .sq-69 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="68"] .sq-67 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="68"] .sq-69 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="68"] .sq-68 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="67"] .sq-66 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="67"] .sq-68 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="67"] .sq-67 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="66"] .sq-65 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="66"] .sq-67 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="66"] .sq-66 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="65"] .sq-64 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="65"] .sq-66 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="65"] .sq-65 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="64"] .sq-63 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="64"] .sq-65 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="64"] .sq-64 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="63"] .sq-62 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="63"] .sq-64 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="63"] .sq-63 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="62"] .sq-61 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="62"] .sq-63 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="62"] .sq-62 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="61"] .sq-60 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="61"] .sq-62 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="61"] .sq-61 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="60"] .sq-59 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="60"] .sq-61 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="60"] .sq-60 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="59"] .sq-58 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="59"] .sq-60 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="59"] .sq-59 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="58"] .sq-57 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="58"] .sq-59 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="58"] .sq-58 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="57"] .sq-56 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="57"] .sq-58 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="57"] .sq-57 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="56"] .sq-55 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="56"] .sq-57 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="56"] .sq-56 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="55"] .sq-54 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="55"] .sq-56 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="55"] .sq-55 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="54"] .sq-53 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="54"] .sq-55 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="54"] .sq-54 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="53"] .sq-52 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="53"] .sq-54 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="53"] .sq-53 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="52"] .sq-51 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="52"] .sq-53 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="52"] .sq-52 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="51"] .sq-50 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="51"] .sq-52 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="51"] .sq-51 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="50"] .sq-49 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="50"] .sq-51 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="50"] .sq-50 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="49"] .sq-48 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="49"] .sq-50 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="49"] .sq-49 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="48"] .sq-47 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="48"] .sq-49 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="48"] .sq-48 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="47"] .sq-46 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="47"] .sq-48 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="47"] .sq-47 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="46"] .sq-45 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="46"] .sq-47 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="46"] .sq-46 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="45"] .sq-44 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="45"] .sq-46 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="45"] .sq-45 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="44"] .sq-43 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="44"] .sq-45 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="44"] .sq-44 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="43"] .sq-42 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="43"] .sq-44 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="43"] .sq-43 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="42"] .sq-41 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="42"] .sq-43 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="42"] .sq-42 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="41"] .sq-40 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="41"] .sq-42 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="41"] .sq-41 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="40"] .sq-39 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="40"] .sq-41 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="40"] .sq-40 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="39"] .sq-38 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="39"] .sq-40 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="39"] .sq-39 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="38"] .sq-37 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="38"] .sq-39 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="38"] .sq-38 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="37"] .sq-36 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="37"] .sq-38 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="37"] .sq-37 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="36"] .sq-35 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="36"] .sq-37 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="36"] .sq-36 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="35"] .sq-34 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="35"] .sq-36 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="35"] .sq-35 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="34"] .sq-33 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="34"] .sq-35 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="34"] .sq-34 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="33"] .sq-32 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="33"] .sq-34 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="33"] .sq-33 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="32"] .sq-31 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="32"] .sq-33 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="32"] .sq-32 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="31"] .sq-30 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="31"] .sq-32 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="31"] .sq-31 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="30"] .sq-29 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="30"] .sq-31 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="30"] .sq-30 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="29"] .sq-28 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="29"] .sq-30 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="29"] .sq-29 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="28"] .sq-27 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="28"] .sq-29 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="28"] .sq-28 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="27"] .sq-26 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="27"] .sq-28 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="27"] .sq-27 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="26"] .sq-25 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="26"] .sq-27 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="26"] .sq-26 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="25"] .sq-24 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="25"] .sq-26 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="25"] .sq-25 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="24"] .sq-23 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="24"] .sq-25 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="24"] .sq-24 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="23"] .sq-22 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="23"] .sq-24 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="23"] .sq-23 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="22"] .sq-21 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="22"] .sq-23 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="22"] .sq-22 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="21"] .sq-20 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="21"] .sq-22 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="21"] .sq-21 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="20"] .sq-19 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="20"] .sq-21 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="20"] .sq-20 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="19"] .sq-18 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="19"] .sq-20 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="19"] .sq-19 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="18"] .sq-17 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="18"] .sq-19 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="18"] .sq-18 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="17"] .sq-16 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="17"] .sq-18 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="17"] .sq-17 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="16"] .sq-15 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="16"] .sq-17 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="16"] .sq-16 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="15"] .sq-14 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="15"] .sq-16 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="15"] .sq-15 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="14"] .sq-13 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="14"] .sq-15 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="14"] .sq-14 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="13"] .sq-12 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="13"] .sq-14 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="13"] .sq-13 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="12"] .sq-11 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="12"] .sq-13 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="12"] .sq-12 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="11"] .sq-10 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="11"] .sq-12 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="11"] .sq-11 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="10"] .sq-9 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="10"] .sq-11 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="10"] .sq-10 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="9"] .sq-8 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="9"] .sq-10 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="9"] .sq-9 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="8"] .sq-7 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="8"] .sq-9 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="8"] .sq-8 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="7"] .sq-6 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="7"] .sq-8 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="7"] .sq-7 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="6"] .sq-5 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="6"] .sq-7 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="6"] .sq-6 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="5"] .sq-4 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="5"] .sq-6 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="5"] .sq-5 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="4"] .sq-3 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="4"] .sq-5 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="4"] .sq-4 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="3"] .sq-2 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="3"] .sq-4 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="3"] .sq-3 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="2"] .sq-1 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="2"] .sq-3 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="2"] .sq-2 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco[data-sq="1"] .sq-0 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="1"] .sq-2 {
    opacity: 1;
    z-index: 10;
  }
  [platform] .eco[data-sq="1"] .sq-1 {
    opacity: 1;
    z-index: 20;
  }
  [platform] .eco .video video {
    width: auto;
    height: 30.5rem;
  }
}
@media (min-width: 1600px) {
  [platform] .hero .scene-1 h2 img {
    width: 62rem;
  }
  [platform] .hero .scene-1 h2 ~ p {
    font-size: 8rem;
    line-height: 0.0625rem;
    line-height: 1;
  }
  [platform] .hero .scene-2 p + p {
    font-size: 8rem;
    line-height: 0.0625rem;
    line-height: 1;
  }
  [platform] .seq {
    height: 68rem;
  }
  [platform] .seq video {
    width: auto;
    height: 50rem;
  }
  [platform] .seq.cartridge video {
    width: auto;
    height: 50rem;
  }
  [platform] .seq.os video {
    width: 30.75rem;
  }
  [platform] .reason p {
    font-size: 8rem;
    line-height: 0.0625rem;
    line-height: 1;
  }
  [platform] .feature li video {
    width: 40rem;
    height: 40rem;
  }
  [platform] .structure img {
    width: 70rem;
  }
  [platform] .custom .viewer {
    height: 53rem;
  }
  [platform] .custom .viewer .control {
    width: 54rem;
    padding-left: 5rem;
    box-sizing: border-box;
  }
  [platform] .custom .viewer .control .slider .swiper-navigation a {
    margin-top: -0.5rem;
  }
  [platform] .custom .viewer .control .slider .swiper-navigation .swiper-button-prev {
    left: 16rem;
  }
  [platform] .custom .viewer .control .slider .swiper-navigation .swiper-button-next {
    right: 16rem;
  }
  [platform] .custom .viewer .control .slider + .slider {
    margin-top: 2rem;
  }
  [platform] .custom .viewer .control .slider + .slider img {
    padding-top: 7rem;
    padding-right: 0rem;
    padding-bottom: 1.5rem;
    padding-left: 0rem;
  }
  [platform] .custom .viewer .control .slider + .slider [slider] {
    min-height: 17rem;
  }
  [platform] .custom .viewer .control .slider + .slider [slider] + p {
    margin-top: 4.125rem;
  }
  [platform] .custom .viewer .result {
    width: 35.9375rem;
  }
  [platform] .spec ul {
    padding-top: 0rem;
    padding-right: 18rem;
    padding-bottom: 0rem;
    padding-left: 18rem;
  }
  [platform] .spec li:first-child dt:first-child {
    margin-left: -18rem;
  }
  [platform] .spec li:last-child dt:first-child {
    margin-right: -18rem;
  }
  [platform] .eco .img {
    width: 90rem;
    height: 28rem;
  }
}
